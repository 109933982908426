import "./styles.css"
import React, {useState} from 'react'
import Slide0 from './Slide0'
import Slide1 from './Slide1'
import Slide2 from './Slide2'
import Slide3 from './Slide3'
import Slide4 from './Slide4'
import Slide5 from './Slide5'
import Slide6 from './Slide6'
import Slide7 from './Slide7'
import Slide8 from './Slide8'
import Slide9 from './Slide9'
import Slide10 from './Slide10'
import Slide11 from './Slide11'

export default function App() {
  const [curSlideNum, setCurSlideNum] = useState(0)
  const slides = [
    {
      'slide': <Slide0 />,
      'transition': 'none'
    },
    {
      'slide': <Slide1 />,
      'transition': 'none'
    },
    {
      'slide': <Slide2 />,
      'transition': 'none'
    },
    {
      'slide': <Slide3 />,
      'transition': 'none'
    },
    {
      'slide': <Slide4 />,
      'transition': 'none'
    },
    {
      'slide': <Slide5 />,
      'transition': 'none'
    },
    {
      'slide': <Slide6 />,
      'transition': 'none'
    },
    {
      'slide': <Slide7 />,
      'transition': 'none'
    },
    {
      'slide': <Slide8 />,
      'transition': 'none'
    },
    {
      'slide': <Slide9 />,
      'transition': 'none'
    },
    {
      'slide': <Slide10 />,
      'transition': 'none'
    },
    {
      'slide': <Slide11 />,
      'transition': 'none'
    },
  ]

  const advanceSlide = () => {
    if (curSlideNum === 0) {
      document.documentElement.requestFullscreen()
    } else if (curSlideNum === slides.length - 2) {
      document.exitFullscreen()
    }
    setCurSlideNum(curSlideNum + 1)
  }

  return (
    <div className="App">
      {curSlideNum > 0 &&
        <div className="button back" onClick={() => {setCurSlideNum(curSlideNum - 1)}}>
          <span>&larr;</span>
        </div>}
      {slides[curSlideNum].slide}
      {curSlideNum < slides.length - 1 &&
        <div className="button forward" onClick={advanceSlide}>
          <span>&rarr;</span>
        </div>}
    </div>
  );
}
