import React from "react"
import Tooltip from './Tooltip'

export default function Slide9() {  
  return (
    <div className="img-slide">
      <img
        src='img/woman-table.png'
        style={{'maxWidth': '100vw', 'maxHeight': '100vh'}}
      />
      <Tooltip html="The American woman enjoys the latest in domestic technology, liberating her from the most mundane parts of cooking, cleaning, and other household tasks. But in the Soviet Union, it is not convenience and comfort but &quot;efficiency&quot; that guides the decisions of designers and architects. Observe here a woman forced to do her needlework on a cramped table that must be turned back into a bed come nightfall." />
    </div>
  )
}