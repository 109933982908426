import React from "react"
import ImageMapper from 'react-img-mapper'
import Tooltip from './Tooltip'

export default function Slide8() {
  const [maxWidth, setMaxWidth] = React.useState(0)
  const [areaId, setAreaId] = React.useState('')
  const [tooltipText, setTooltipText] = React.useState('')
  const tooltips: {[name : string] : string} = {
    'walls': 'The subtle refinement of crown molding and an elegant baseboard, commonplace touches of domesticity and sophistication in the American home, must be rejected in favor of a barren wall in the <em>khrushchevka</em>.',
    'door': 'Every American knows the importance of his front door: the gateway to the home, to the sphere of comfort and family. But in the <em>khrushchevka</em>, it too is subject to the stifling mandates of communist design: bare, uninviting, and devoid of individuality.',
    'room': 'In this illustration of the renovation of a Soviet living space, we see the fullest expression of the Soviet vision of the home: stripped of the luxuries one has earned and the character and memories by which one comes to recognize a dwelling as home.'
  }

  const map = {
    name: "map2",
    areas: [
      {
        name: "walls",
        shape: "circle",
        coords: [550, 680, 70],
        preFillColor: "rgba(15,150,255,0.75)"
      },
      {
        name: "door",
        shape: "circle",
        coords: [420, 1700, 70],
        preFillColor: "rgba(15,150,255,0.75)"
      },
      {
        name: "room",
        shape: "circle",
        coords: [1900, 975, 70],
        preFillColor: "rgba(15,150,255,0.75)"
      }
    ]
  }

  const didClick = (name : string) => {
    setTooltipText(tooltips[name])
  }

  React.useEffect(() => {
    if (areaId !== '') {
      didClick(areaId)
    }
  }, [areaId])
  
  return (
    <div className="img-slide">
      <ImageMapper
        src='img/multiphoto.png'
        map={map}
        responsive={true}
        parentWidth={maxWidth === 0 ? window.innerWidth : maxWidth}
        onLoad={(imgRef, { width, height }) => {
          if (maxWidth > 0) return
          if (height > window.innerHeight) {
            setMaxWidth(window.innerHeight * width / height)
          } else {
            setMaxWidth(window.innerWidth)
          }
        }}
        onClick={(area : any) => setAreaId(area.name)}
      />
      <Tooltip html={tooltipText} />
    </div>
  )
}