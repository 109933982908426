import React from "react"
import Typewriter from "./Typewriter"

export default function Slide1() {
  const [showBody, setShowBody] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowBody(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="slide intro">
      <p className="top">July 24, 1964</p>
      {showBody && <p className="no-fade">
        <Typewriter
          text="Today marks five years since the American Exhibition in Moscow, a display of scientific prowess and technological innovation made possible by the American way of life."
        />
      </p>}
    </div>
  )
}