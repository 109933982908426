import React from "react"

export default function Slide1() {
  return (
    <div className="intro slide citations">
      <h3 className="top">Image Sources</h3>
      <p><a target="_blank" rel="noopener noreferrer" href="https://languagecollections-blog.lib.cam.ac.uk/2015/02/16/makeshift-modernity-diy-craft-and-the-virtuous-homemaker-in-new-soviet-housing-of-the-1960s/">"1964 apartment block in Moscow." 16 Feb. 2015. <em>Languages across Borders</em>, University of Cambridge.</a></p>
      <p><a target="_blank" rel="noopener noreferrer" href="https://www.telegraph.co.uk/lifestyle/interiors/10891877/British-homes-through-the-ages-in-pics.html?frame=2937973">"British homes through the ages: in pics". <em>The Telegraph</em>, 11 June 2014.</a></p>
      <p><a target="_blank" rel="noopener noreferrer" href="https://www.semanticscholar.org/paper/From-%22Stalinkas%22-to-%22Khrushchevkas%22%3A-The-Transition-Choate/76fa8e1aac6a301a08df21eb8a1d516dedb0cff1">Choate, Ksenia. 2010. “From 'Stalinkas' to 'Khrushchevkas': The Transition to Minimalism in Urban Residential Interiors in the Soviet Union from 1953 to 1964.”</a></p>
      <p><a target="_blank" rel="noopener noreferrer" href="https://envisioningtheamericandream.com/2012/09/24/a-blueprint-for-the-middle-class/">Edelstein, Sally. "A Blueprint for the Middle Class." <em>Envisioning the American Dream</em>, 24 Sep. 2012.</a></p>
      <p><a target="_blank" rel="noopener noreferrer" href="https://web.archive.org/web/20180901225641/http://www.altayrealt.ru/articles/431578-khrushchevka-trushchoby-ili-simvol-sssr.html">"Khrushchevka: slums or symbol of the USSR?" 2018. <em>Altai Real Estate Portal</em>. In the <em>Internet Archive</em>.</a></p>
      <p><a target="_blank" rel="noopener noreferrer" href="https://rogerwilkerson.tumblr.com/post/51765391856/this-is-for-us-detail-from-1954-national-homes">"This is for Us!" 1954. <em>Roger Wilkerson</em>, Tumblr.</a></p>
    </div>
  )
}