import React from "react"
import Typewriter from "./Typewriter"

export default function Slide1() {
  return (
    <div className="slide">
      <p>
        <Typewriter
          text="Are the Soviet people simply anomalous, lacking that basic spirit of self-determination and individuality that we Americans know to be essential features of mankind? In the publications of the Soviet regime we find our answer: this is not a natural way of living but rather one that has been stamped into every resident of these dwellings by the suffocating dictates of the Soviet regime. What follow are just a few of the stringent limitations on freedom in the communist home."
        />
      </p>
    </div>
  )
}