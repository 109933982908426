import React, { useEffect } from 'react'

export default function Tooltip({html} : {html : string}) {
  const [collapse, setCollapse] = React.useState(false)
  useEffect(() => setCollapse(false), [html])
  return (
    <>
      {html !== '' && <div className="tooltip">
          <div className="tooltip-header">
            <button onClick={() => setCollapse(!collapse)}>&mdash;</button>
          </div>
          {!collapse && <div className="tooltip-body">
            <p dangerouslySetInnerHTML={{ __html: html }}></p>
          </div>}
        </div>}
    </>
  )
}