import React from "react"
import Tooltip from './Tooltip'

export default function Slide4() {  
  return (
    <div className="img-slide">
      <img
        src='img/living-room.png'
        style={{'width': '100vw'}}
      />
      <Tooltip html="Here we see the <em>khrushchevka</em> living room. Whose living room? It could be anyone's: plain, unadorned, bare, and utterly impersonal, it clearly demonstrates the Soviets' lack not only of those advanced technologies that make the American house the envy of the world but also the most basic marks of individuality, self-expression, and refinement that are the pride of every American homeowner." />
    </div>
  )
}