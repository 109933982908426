import React, { useState } from 'react'
import Typewriter from './Typewriter'

export default function Slide2() {
  const [showFootnote, setShowFootnote] = useState(false)

  return (
    <div className="slide">
      <p>
        <Typewriter
          text="Five years ago, then-Vice President Nixon soundly defeated Soviet Premier Khrushchev's feeble attempts to belittle the modern American home through a convincing demonstration of the superior convenience, affordability, and standard of living within reach of every American today."
          onFinish={() => setTimeout(() => setShowFootnote(true), 1000)}
        />{showFootnote && <sup style={{'fontSize': '12pt'}}>*</sup>}
      </p>

      {showFootnote &&
        <p style={{'fontSize': '12pt', 'position': 'absolute', 'bottom': '3px', 'left': '0', 'right': '0', 'color': 'gray'}}><sup>*</sup>Exclusions apply. Availability of suburban housing to persons of certain ethnic and racial backgrounds may be legally proscribed.</p>}
    </div>
  )
}