import React from "react"
import ImageMapper from 'react-img-mapper'
import Tooltip from './Tooltip'

export default function Slide4() {
  const [maxWidth, setMaxWidth] = React.useState(0)
  const [areaId, setAreaId] = React.useState('')
  const [tooltipText, setTooltipText] = React.useState('')
  const tooltips: {[name : string] : string} = {
    'car': 'Here we see a Soviet automobile. What we do not see are the scores of grumbling neighbors protesting its presence, for the opponents of the automobile—that shining emblem of progress, freedom, and self-sufficiency—are legion among the Soviet people.',
    'building': 'From a nearer distance, one can see the prefabricated concrete blocks from which the <em>khruchschevka</em> are made. As the Soviet bureaucracy devotes untold resources to producing these drab panels at ever-increasing speed, thousands of these indistinguishable buildings appear faster than one can blink on the streets of Soviet cities.'
  }

  const map = {
    name: "map2",
    areas: [
      {
        name: "building",
        shape: "circle",
        coords: [800, 500, 70],
        preFillColor: "rgba(15,150,255,0.75)"
      },
      {
        name: "car",
        shape: "circle",
        coords: [1700, 2100, 70],
        preFillColor: "rgba(15,150,255,0.75)"
      }
    ]
  }

  const didClick = (name : string) => {
    setTooltipText(tooltips[name])
  }

  React.useEffect(() => {
    if (areaId !== '') {
      didClick(areaId)
    }
  }, [areaId])
  
  return (
    <div className="img-slide">
      <ImageMapper
        src='img/single-khrushchevka.jpg'
        map={map}
        responsive={true}
        parentWidth={maxWidth === 0 ? window.innerWidth : maxWidth}
        onLoad={(imgRef, { width, height }) => {
          if (maxWidth > 0) return
          if (height > window.innerHeight) {
            setMaxWidth(window.innerHeight * width / height)
          } else {
            setMaxWidth(window.innerWidth)
          }
        }}
        onClick={(area : any) => setAreaId(area.name)}
      />
      <Tooltip html={tooltipText} />
    </div>
  )
}