import React, {useEffect, useState} from "react"

export interface TypewriterProps {
  text : string,
  delay? : number,
  onFinish?: () => void
}

// Source: https://blog.logrocket.com/3-ways-implement-typing-animation-react/
export default function Typewriter ({ text, delay = 75, onFinish = () => {} } : TypewriterProps) {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const isPause = ['.', '?', '—', ';', ':', '!'].indexOf(text[currentIndex - 1]) > -1
                      && text.slice(currentIndex - 3, currentIndex - 1) !== 'Mr'
      const timeout = setTimeout(() => {
        if (text[currentIndex] === ' ' && currentIndex + 1 < text.length) {
          setCurrentText(prevText => prevText + text[currentIndex] + text[currentIndex + 1])
          setCurrentIndex(prevIndex => prevIndex + 2)
        } else {
          setCurrentText(prevText => prevText + text[currentIndex])
          setCurrentIndex(prevIndex => prevIndex + 1)
        }
      }, delay + (isPause ? 500 : 0));
  
      return () => clearTimeout(timeout);
    } else {
      onFinish()
    }
  }, [currentIndex, delay, text]);

  return <span>{currentText}</span>;
};