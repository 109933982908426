import React, { useEffect } from "react"
import ImageMapper from 'react-img-mapper'
import Tooltip from './Tooltip'

export default function Slide4() {
  const [maxWidth, setMaxWidth] = React.useState(0)
  const [areaId, setAreaId] = React.useState('')
  const [tooltipText, setTooltipText] =
    React.useState('Click on the highlighted areas of the image to explore.')
  const [tooltipIdx, setTooltipIdx] = React.useState(0)
  const [tooltipLookup, setTooltipLookup] = React.useState<{[key : string] : string}>({})

  const tooltips = [
    "This is a <em>khrushchevka</em>, the state-of-the-art Soviet dwelling. Residents are packed into cramped apartments like sardines in a can, provided they can first manage to bribe enough bureaucrats to move to the top of the overflowing waiting lists for housing.",
    "This is also a <em>khrushchevka</em>. You would be forgiven for failing to distinguish it from the first, for they are identical. As is the communist way, bare efficiency and bureaucratic imperatives trump convenience and individual expression in Soviet housing.",
    "This too is a <em>khrushchevka</em>. If you are becoming bored by the monotony of these indistinguishable buildings, simply imagine the tedium of waking up each day to this landscape."
  ]

  const didClickKhrushchevka = (name : string) => {
    console.log(name, tooltipLookup, tooltipIdx, name in tooltipLookup)
    if (name in tooltipLookup) {
      setTooltipText(tooltipLookup[name])
    } else {
      const thisTooltip = tooltips[tooltipIdx]
      setTooltipIdx(tooltipIdx + 1)
      setTooltipLookup({...tooltipLookup, [name]: thisTooltip})
      setTooltipText(thisTooltip)
    }
  }

  const map = {
    name: "map1",
    areas: [
      {
        name: "front",
        shape: "circle",
        coords: [255, 440, 20],
        preFillColor: "rgba(15,150,255,0.75)"
      },
      {
        name: "middle",
        shape: "circle",
        coords: [530, 420, 18],
        preFillColor: "rgba(15,150,255,0.75)"
      },
      {
        name: "back",
        shape: "circle",
        coords: [750, 395, 12],
        preFillColor: "rgba(15,150,255,0.75)"
      }
    ]
  }

  useEffect(() => {
    if (areaId !== '') {
      didClickKhrushchevka(areaId)
    }
  }, [areaId])
  
  return (
    <div className="img-slide">
      <ImageMapper
        src='img/multiple-khrushchevka.jpg'
        map={map}
        responsive={true}
        parentWidth={maxWidth === 0 ? window.innerWidth : maxWidth}
        onLoad={(imgRef, { width, height }) => {
          if (maxWidth > 0) return
          if (height > window.innerHeight) {
            setMaxWidth(window.innerHeight * width / height)
          } else {
            setMaxWidth(window.innerWidth)
          }
        }}
        onClick={(area : any) => setAreaId(area.name)}
      />
      <Tooltip html={tooltipText} />
    </div>
  )
}