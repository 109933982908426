import React from "react"
import Tooltip from './Tooltip'

export default function Slide9() {  
  return (
    <div className="img-slide">
      <img
        src='img/us-multiphoto.png'
        style={{'maxWidth': '100vw', 'maxHeight': '100vh'}}
      />
      <Tooltip html="We have seen, then, what is domestic life in the Soviet union: cramped, devoid of character, and lacking in both the architectural and technological luxuries that Americans have come to know and love in our thriving suburbs. In stark contrast stands the American home, which, as our former vice president stated five years ago today, &quot;is designed to take advantage of new inventions and new techniques&quot; and to ensure the home buyer's &quot;right to choose.&quot; And the choice between the Soviet housing we have seen today and the verdant comfort of the American suburbs must clearly be the easiest of them all." />
    </div>
  )
}