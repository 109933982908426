import React from "react"
import Typewriter from "./Typewriter"

export default function Slide1() {
  return (
    <div className="slide">
      <p>
        <Typewriter
          text="Today, we take a tour of the latest in Soviet living, which will provide indisputable evidence of the enduring truth of Mr. Nixon's assertions: then, as now, American values—among them family, freedom, and the opportunity for every man to build for himself a better life through hard work and perseverance—produce a society unparalleled by any in our world today, most especially the Soviet Union."
        />
      </p>
    </div>
  )
}